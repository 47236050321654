import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';

export const getUsers = createAsyncThunk(
  'usersApp/users/getUsers',
  async ({ page, limit }, { dispatch }) => {
    const response = await axios.get('/users', {
      params: {
        page: page,
        limit,
      },
    });

    console.log(response.data);
    dispatch(setTotalResults(response.data.total));
    return response.data.results;
  }
);

export const blcokUser = createAsyncThunk(
  'usersApp/users/blcokUser',
  async ({userId, isBlocked}) => {
    const response = await axios.post(`users/${userId}`, {
      isBlocked
    });
    return response.data;
  }
);

const usersSlice = createSlice({
  name: 'usersApp/users',
  initialState: {
    totalResults: 0,
    data: [],
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      state.data = action.payload
    },
  },
});

export const {
  setTotalResults,
} = usersSlice.actions;

export default usersSlice.reducer;

