import Profile from "layouts/pages/profile";
import Users from "layouts/pages/users";
import Products from "layouts/pages/products";
import Inquirys from "layouts/pages/inquirys";
import BidsEnded from "layouts/pages/bidsEnded";
import Blogs from "layouts/pages/blogs";

const routes = [
  {
    name: "Products",
    route: "/products",
    component: <Products />,
    isAuth: true
  },
  {
    name: "Inquirys",
    route: "/inquirys",
    component: <Inquirys />,
    isAuth: true
  },
  {
    name: "Users",
    route: "/users",
    component: <Users />,
    isAuth: true
  },
  {
    name: "Bids Ended",
    route: "/bidsEnded",
    component: <BidsEnded />,
    isAuth: true
  },
  {
    name: "Blogs",
    route: "/blogs",
    component: <Blogs />,
    isAuth: true
  },
  {
    name: "Profile",
    route: "/profile",
    component: <Profile />,
    isAuth: true
  },
];

export default routes;
