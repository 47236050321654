
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
import { useDispatch, useSelector } from 'react-redux';
import MKPagination from "components/MKPagination";
import Icon from "@mui/material/Icon";
import { getUsers, blcokUser } from "../store/usersSlice";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";
import Switch from "@mui/material/Switch";
import Modal from '@mui/material/Modal';


function UsersList() {
  const navigate = useNavigate();
  const users = useSelector(({ users }) => users.users.data);
  const totalResults = useSelector(({ users }) => users.users.totalResults);
  const [open, setOpen] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [page, setPage] = useState(1)
  const [numOfpage, setNumOfPage] = useState(1)
  const [isError, setIsError] = useState(false)
  const dispatch = useDispatch()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };


  const handleBlockUser = (user) => {
    setUserSelected(user)
    setOpen(true)
  };

  const handleBlock = () => {
    const reqParams = {
      isBlocked: !userSelected.isBlocked,
      userId: userSelected.id
    }
    dispatch(blcokUser(reqParams)).then(({ payload }) => {
      handleClose()
      if (payload.id) {
        getUsersData(page)
      } else {
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 3000);
      }
    })
  }
  const getUsersData = (page) => {
    setPage(page)
    dispatch(getUsers({ page, limit: 10 }))
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (totalResults > 0) {
      setNumOfPage(Math.ceil(totalResults / 9))
    }
  }, [totalResults])

  const renderPagination = () => {
    const renderPages = (e, index) => {
      return <MKPagination
        onClick={() => getUsersData(index + 1)}
        active={index + 1 === page}
        key={index} item>
        {index + 1}
      </MKPagination>
    }

    return (
      <Container >
        <Grid container item justifyContent="center" xs={12} lg={6} mx="auto">
          <MKPagination>
            {<MKPagination disabled={page === 1} item onClick={() => getUsersData(page - 1)}>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>}
            {
              [...Array(numOfpage)].map(renderPages)
            }
            <MKPagination disabled={page === numOfpage} item onClick={() => getUsersData(page + 1)}>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </Grid>
      </Container>
    );
  }

  const renderUsers = () => {
    if (!users) {
      return <></>
    }
    return (
      <Grid container spacing={3} sx={{ mb: 10 }} key={"title"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableBody>
              <TableRow >
                <TableCell align="left"><h3>Name</h3></TableCell>
                <TableCell align="center"><h3>Mobile Number</h3></TableCell>
                <TableCell align="center"><h3>Email</h3></TableCell>
                <TableCell align="center"><h3>Gender</h3></TableCell>
                <TableCell align="center"><h3>Blocked</h3></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={user.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{user.firstName} {user.lastName}</TableCell>
                  <TableCell align="center">{user.phoneNumber}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.gender}</TableCell>
                  <TableCell align="center">
                    <Switch color="info" checked={user.isBlocked} onChange={() => handleBlockUser(user)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          {renderPagination()}
        </Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MKBox sx={style}>
            <MKTypography id="modal-modal-title" variant="h6" component="h2">
              Alert
            </MKTypography>
            <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
              {`are you sure want to ${userSelected?.isBlocked ? "Unblock" : "Block"} user ${userSelected?.email}?`}
            </MKTypography>
            <Container>
              <MKBox sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4 }}>
                <MKButton onClick={handleBlock} variant="outlined" color="error">{`${userSelected?.isBlocked ? "Unblock" : "Block"}`}</MKButton>
                <MKButton sx={{ mr: 1 }} onClick={handleClose} variant="outlined" color="info">Cancel</MKButton>
              </MKBox>
            </Container>
          </MKBox>
        </Modal>
      </Grid>)
  }


  return (
    <MKBox component="section" my={6} py={1}>
      {isError && <MKAlert color="error">Error to Block/Unblock user please try again later</MKAlert>}
      <Container sx={{ mt: 6 }}>{
        renderUsers()}
      </Container>
    </MKBox>
  );
}

export default UsersList;
