import { useEffect } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import DefaultNavbar from "Navbars/DefaultNavbar";
import ProductsList from "pages/BidsEnded/components/ProductsList";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from "./store/bidsEndedSlice";
import colors from "assets/theme/base/colors";
import { useNavigate } from "react-router-dom";

function BidsEnded() {
    const dispatch = useDispatch()
    const user = useSelector(({ auth }) => auth.user.data);
    const navigate = useNavigate();

    const getProductsData = () => {
        dispatch(getProducts({ page: 0, limit: 9 }))
    }

    useEffect(() => {
        getProductsData()
    }, [dispatch])


    const getNavbar = () => {
        if (user?.id) {
            return <DefaultNavbar
                routes={routes}
                sticky
            />
        }
        return <DefaultNavbar
            routes={routes}
            action={{
                type: "internal",
                route: "/sign-in",
                label: "Login",
                color: "info",
            }}
            sticky
        />
    };
    return (<>
        {getNavbar()}
        <MKBox
            minHeight="20vh"
            width="100%"
            sx={{
                backgroundColor: colors.dark.main
            }}
        />
        <Card
            sx={{
                p: 2,
                mx: { xs: 2, lg: 3 },
                mt: -5,
                mb: 4,
                backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
                backdropFilter: "saturate(200%) blur(30px)",
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
        >
            <Container>
                <ProductsList />
            </Container>
        </Card>
    </>)
}

export default BidsEnded;
