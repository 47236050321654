import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKError from "components/MKError";
import DefaultNavbar from "Navbars/DefaultNavbar";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux';
import { getProduct, deleteProduct } from "./store/productSlice";
import { useParams } from "react-router-dom";
import MKButton from "components/MKButton";
import ProductForm from "./components/Form";
import ProductHistory from "./components/ProductHistory";
import colors from "assets/theme/base/colors";
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function Product() {
  const [product, setProduct] = useState(null)
  const [history, setHistory] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [activeTab, setActiveTab] = useState(0);
  const user = useSelector(({ auth }) => auth.user.data);
  const dispatch = useDispatch()
  const { productId } = useParams()
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const defaultProduct = {
    name: "",
    description: "",
    isSoldOut: false,
    value: 0,
    images: [],
    shipmentNumber: "",
    shipmentLocation: "",
    numberOfPieces: 0,
    endBid: new Date().setDate(new Date().getDate() + 7),
    startBid: new Date(),
    isPublish: true,
  }

  const getProductData = () => {
    if (productId === 'new') {
      setProduct(defaultProduct)
      setLoading(false)
    } else {
      setLoading(true)
      dispatch(getProduct(productId)).then(({ payload }) => {
        
        if (payload) {
          setProduct(payload.product)
          setHistory(payload.history)
        } else {
          setError(true)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        setError(true)
      })

    }
  }

  useEffect(() => {
    getProductData()
  }, [])

  const getNavbar = () => {
    if (user?.id) {
      return <DefaultNavbar
        routes={routes}
        sticky
      />
    }
    return <DefaultNavbar
      routes={routes}
      action={{
        type: "internal",
        route: "/sign-in",
        label: "Login",
        color: "info",
      }}
      sticky
    />
  };

  const handleRemove = () => {
    dispatch(deleteProduct(productId)).then(({ payload }) => {
      if (payload?.success) {
        navigate("/products")
      } else {
        setError(true)
      }
    })
  }

  const renderTabs = () => {
    if(productId === 'new') return <></>
    return (
      <Container>
        <Grid container item justifyContent="center" xs={12} mb={2} lg={4} mx="auto">
          <AppBar position="static">
            <Tabs value={activeTab} onChange={handleTabType}>
              <Tab label="Info" />
              <Tab label="History" />
            </Tabs>
          </AppBar>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      {getNavbar()}
      <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundColor: colors.dark.main
        }}
      >
        <Container>
        </Container>
      </MKBox>
      <Card
        sx={{
          minHeight: "50vh",
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -5,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {renderTabs()}

        {error && <MKError />}
        {(!error && productId !== 'new') && <Container>
          <MKBox sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <MKButton onClick={handleOpen} variant="gradient" color="error">Delete Product</MKButton>
          </MKBox>
        </Container>}
        {activeTab == 0 && (!loading && !error) && <ProductForm product={product} />}
        {activeTab == 1 && (!loading && !error) && <ProductHistory history={history} />}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MKBox sx={style}>
            <MKTypography id="modal-modal-title" variant="h6" component="h2">
              Alert
            </MKTypography>
            <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
              are you sure want to delete this product?
            </MKTypography>
            <Container>
              <MKBox sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4 }}>
                <MKButton onClick={handleRemove} variant="outlined" color="error">Delete</MKButton>
                <MKButton sx={{ mr: 1 }} onClick={handleClose} variant="outlined" color="info">Cancel</MKButton>
              </MKBox>
            </Container>
          </MKBox>
        </Modal>
      </Card>
    </>
  );
}

export default Product;
