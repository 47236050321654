import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux';
// import Products from "layouts/pages/Products";
import Products from "layouts/pages/products";
import Product from "layouts/pages/product";
import SignUp from "layouts/pages/authentication/sign-up";
import SignIn from "layouts/pages/authentication/sign-in";
import Home from "layouts/pages/home";
import Inquirys from "layouts/pages/inquirys";
import ContactUs from "layouts/pages/contact-us";
import Profile from "layouts/pages/profile";
import Users from "layouts/pages/users";
import BidsEnded from "layouts/pages/bidsEnded";
import Blogs from "layouts/pages/blogs";
import Blog from "layouts/pages/blog";
const MainRoutes = () => {

  const user = useSelector(({ auth }) => auth.user.data);

  const getRoutes = (allRoutes) => {
    if (user.id) {
      return <>
        <Route key="Home" path="/home" element={<Home />} />
        <Route key="Products" path="/products" element={<Products />} />
        <Route key="Product" path="/product/:productId" element={<Product />} />
        <Route key="Blog" path="/blog/:blogId" element={<Blog />} />
        <Route key="Blogs" path="/blogs" element={<Blogs />} />
        <Route key="Profile" path="/profile" element={<Profile />} />
        <Route key="Users" path="/users" element={<Users />} />
        <Route key="BidsEnded" path="/bidsEnded" element={<BidsEnded />} />
        <Route key="Inquirys" path="/inquirys" element={<Inquirys />} />
        <Route key="def" path="*" element={<Navigate to="/home" />} />
      </>
    }
    return <>
      <Route key="Login" path="/sign-in" element={<SignIn />} />
      <Route key="SignUp" path="/sign-up" element={<SignUp />} />
      <Route key="def" path="*" element={<Navigate to="/sign-in" />} />
    </>
  };
  return (<>
    <Routes>
      {getRoutes(routes)}
    </Routes>
  </>)
}
export default MainRoutes;