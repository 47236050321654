import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import DefaultNavbar from "Navbars/DefaultNavbar";
import InquirysList from "pages/Inquirys/components/InquirysList";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux';
import { getInquirys } from "./store/InquirysSlice";
import colors from "assets/theme/base/colors";
import { useNavigate } from "react-router-dom";


function Inquirys() {
  
  const [loading, setLoading] = useState(false)
  const user = useSelector(({ auth }) => auth.user.data);
  const isLogedin = user?.id
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const getInquirysData = () => {
    setLoading(true)
    dispatch(getInquirys({ page: 0, limit: 10 })).then(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getInquirysData()
  }, [])

  const getNavbar = () => {
    if (isLogedin) {
      return <DefaultNavbar
        routes={routes}
        sticky
      />
    }
    return <DefaultNavbar
      routes={routes}
      action={{
        type: "internal",
        route: "/sign-in",
        label: "Login",
        color: "info",
      }}
      sticky
    />
  };
  return (
    <>
      {getNavbar()}
      <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundColor: colors.dark.main
        }}
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -5,
          mb: 4,
          minHeight:"60vh",
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {!loading && <InquirysList />}
      </Card>
    </>
  );
}

export default Inquirys;
