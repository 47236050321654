

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import DefaultNavbar from "Navbars/DefaultNavbar";

import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from "../../../auth/store/loginSlice"
import { useNavigate } from "react-router-dom";
import routes from "routes"
import { submitRegister } from "../../../auth/store/registerSlice";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignUp() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user.data);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');



  useEffect(() => {
    if (user.id) {
      navigate('/products')
    }
  }, [dispatch, user])

  const submitHandler = () => {
    if (email === '' ||
      password === '' ||
      firstName === '' ||
      lastName === '' ||
      phoneNumber === '') {
      alert("enter")
      return
    }

    const userData = {
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
    }

    dispatch(submitRegister(userData))
  }
  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        width="100%"
        minHeight="100vh"
      // sx={{
      //   backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
      //     `${linearGradient(
      //       rgba(gradients.dark.main, 0.6),
      //       rgba(gradients.dark.state, 0.6)
      //     )}, url(${bgImage})`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
      />
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2} top={100}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Sign up
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox noValidate component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput required variant="standard" onChange={(e) => setFirstName(e.target.value)} label="First Name" fullWidth />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput required variant="standard" label="Last Name" fullWidth onChange={(e) => setLastName(e.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput required variant="standard" label="Mob." fullWidth onChange={(e) => setPhoneNumber(e.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput required variant="standard" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type='password' required variant="standard" label="Password" fullWidth onChange={(e) => setPassword(e.target.value)} />
                  </MKBox>
                  <MKBox mt={4} mb={1}>
                    <MKButton onClick={submitHandler} variant="gradient" color="info" fullWidth>
                      sign up
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      You have an account?
                      <MKTypography
                        component={Link}
                        to="/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign in
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </>
  );
}

export default SignUp;
