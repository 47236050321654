import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useDispatch } from 'react-redux';
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import { createBlog, updateBlog } from "pages/Blog/store/blogSlice";
import { useNavigate } from "react-router-dom";

const BlogForm = ({ blog }) => {
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [title, setTitle] = useState(blog.title);
  const [description, setDescription] = useState(blog.description);
  const [isSoldOut, setIsSoldOut] = useState(blog.isSoldOut);
  const [images, setImages] = useState(blog?.images);
  const [isPublish, setIsPublish] = useState(blog?.isPublish);
  const [isView] = useState(blog?.id);
  const handleIsPublish = () => setIsPublish(!isPublish);
  const handleChecked = () => setIsSoldOut(!isSoldOut);
  const [imagesData, setImagesData] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    setImages(blog?.images)
  }, [])

  const setImagesView = () => {
    const arrData = []
    images.forEach(img => {
      const link = `https://lh3.googleusercontent.com/d/${img}=l10`
      arrData.push({ img: link, featured: true })
    });
    setImagesData(arrData)

  }
  useEffect(() => {
    setImagesView()
  }, [])

  const setReqResponse = (status) => {
    if (status) {
      setIsSaved(true)
      setTimeout(() => {
        setIsSaved(false)
        navigate(`/blogs`)
      }, 2000);
    } else {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 2000);
    }
  }
  const saveBlog = () => {
    const reqParams = {
      isPublish,
      description,
      images,
      title,
    }
    setIsLoading(true)
    if (isView) {
      dispatch(updateBlog({ blog: reqParams, blogId: blog.id })).then(({ payload }) => {
        if (payload) {
          setReqResponse(true)
        } else {
          setReqResponse(false)
        }
      })
    } else {
      dispatch(createBlog(reqParams)).then(({ payload }) => {
        if (payload) {
          setReqResponse(true)
        } else {
          setReqResponse(false)
        }
      })
    }
  }

  const renderImageList = () => {
    return imagesData.map((item, index) => (
      <MKBox
        sx={{
          width: 120,
          height: 166,
          boxShadow: 3,
          m: 1,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <img
          referrerpolicy="no-referrer"
          style={{ width: 120, height: 120, objectFit: 'cover' }}
          src={`${item.img}`}
          srcSet={`${item.img}`}
          alt={'alt'}
          loading="lazy"
        />
        <MKButton sx={{ mt: -1 }} variant="outlined" onClick={() => removeImg(index)} size="smal" border={0} color="error" fullWidth ml={1}>
          <Icon sx={{ mr: 1 }}>delete</Icon>
        </MKButton>
      </MKBox>
    ))
  }

  const removeImg = (index) => {
    const newImgs = images
    console.log()
    newImgs.splice(index, 1)
    setImages(newImgs)
    setTimeout(() => {
      setImagesView()
      setImgUrl('')
    }, 0);
  }
  const addImg = () => {
    if (imgUrl === "") return
    try {
      const imgUrlList = imgUrl.split("/")
      if (imgUrlList.length !== 7) {
        setImgUrl('Invalid Image URL')
        setTimeout(() => {
          setImgUrl('')
        }, 1000);
        return
      }
      const imgId = imgUrlList[5]
      const newImgs = images
      newImgs.push(imgId)
      setImages(newImgs)
      setTimeout(() => {
        setImagesView()
        setImgUrl('')
      }, 0);
    } catch (error) {
      console.log({ error })
      alert("Error to add the image")
    }
  }
  const renderForm = () => {
    return (<MKBox width="100%" component="form">
      <MKBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MKInput variant="standard" defaultValue={blog?.title} onChange={(event) => setTitle(event.target.value)} required label="Title" fullWidth />
          </Grid>
          <Grid item xs={10} >
            <MKInput variant="standard" value={imgUrl} onChange={(event) => setImgUrl(event.target.value)} label="Images" fullWidth />
          </Grid>
          <Grid item xs={2} >
            <MKButton onClick={addImg} size="small" color="info">
              <Icon sx={{ mr: 1 }}>add</Icon>
            </MKButton>
          </Grid>
          <Grid item xs={12}>
            <MKInput variant="standard" defaultValue={blog?.description} onChange={(event) => setDescription(event.target.value)} label="Description" required multiline fullWidth rows={6} />
          </Grid>
          <Grid item xs={2} alignItems="center" ml={-1}>
            <Switch checked={isPublish} onChange={handleIsPublish} />
            <MKTypography
              variant="button"
              fontWeight="regular"
              color="text"
              ml={-1}
              sx={{ cursor: "pointer", userSelect: "none" }}
              onClick={handleChecked}
            >
              &nbsp;&nbsp;Published&nbsp;
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12} my={2}>
          <MKButton disabled={isLoading || isSaved} onClick={saveBlog} variant="gradient" color="info" fullWidth>
            save
          </MKButton>
        </Grid>
      </MKBox>
    </MKBox>)
  }

  return (
    <Grid container item xs={12} mt={5}>
      {isError && <MKAlert color="error">Error to save please try again later</MKAlert>}
      {isSaved && <MKAlert color="success">Saved successfully</MKAlert>}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} justifyContent="center" >
          {renderForm()}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container xs={12}>
            {renderImageList()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default BlogForm;
