

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import CounterCard from "components/Cards/CounterCards/DefaultCounterCard";

function Counters() {
  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={4} onClick={() => alert("Counters")}>
            <CounterCard
              count={12354}
              suffix="+"
              title="Users"
              description="text text text text text text text text text text text text text text text "
            />
          </Grid>
          <Grid item xs={12} md={4} display="flex">
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <CounterCard
              count={244}
              suffix="+"
              title="Products"
              description="text text text text text text text text text text text text text text text "
            />
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CounterCard
              count={400000}
              suffix="$"
              title="Revenue"
              description="text text text text text text text text text text text text text text text "
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
