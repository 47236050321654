import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import MainRoutes from "MainRoutes";
import Provider from 'react-redux/es/components/Provider';
import store from './store';
import { Auth } from './auth';

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Auth >
          <MainRoutes />
          <CssBaseline />
        </Auth>
      </Provider>
    </ThemeProvider>
  );
}
