import jwtService from '../services/jwtService';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import MKTloading from "components/MKTloading"
import { setUserData, logoutUser } from './store/userSlice';

function Auth({ children }) {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    jwtCheck()
  }, [])

  const jwtCheck = async () => {
    await jwtService.init();
    await jwtService.signInWithToken().then(user => {
      dispatch(setUserData(user));
      setWaitAuthCheck(false)
    }).catch(error => {
      setWaitAuthCheck(false)
    });
  };

  return waitAuthCheck ? <MKTloading /> : <>{children}</>;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
