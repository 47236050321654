
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useDispatch, useSelector } from 'react-redux';
import MKPagination from "components/MKPagination";
import Icon from "@mui/material/Icon";
import { getProducts } from "../store/productsSlice";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";


function ProductsList() {
  const navigate = useNavigate();
  const products = useSelector(({ products }) => products.products.data);
  const totalResults = useSelector(({ products }) => products.products.totalResults);
  const [page, setPage] = useState(1)
  const [numOfpage, setNumOfPage] = useState(1)
  const dispatch = useDispatch()

  const getProductsData = (page) => {
    setPage(page)
    dispatch(getProducts({ page, limit: 9 }))
  }

  useEffect(() => {
    if (totalResults > 0) {
      setNumOfPage(Math.ceil(totalResults / 9))
    }

  }, [totalResults])
  const renderPagination = () => {
    const renderPages = (e, index) => {
      return <MKPagination
        onClick={() => getProductsData(index + 1)}
        active={index + 1 === page}
        key={index} item>
        {index + 1}
      </MKPagination>
    }

    return (
      <Container >
        <Grid container item justifyContent="center" xs={12} lg={6} mx="auto">
          <MKPagination>
            {<MKPagination disabled={page === 1} item onClick={() => getProductsData(page - 1)}>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>}
            {
              [...Array(numOfpage)].map(renderPages)
            }
            <MKPagination disabled={page === numOfpage} item onClick={() => getProductsData(page + 1)}>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </Grid>
      </Container>
    );
  }

  const renderProducts = () => {
    if (!products) {
      return <></>
    }
    return (
      <Grid container spacing={3} sx={{ mb: 10 }} key={"title"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableBody>
              <TableRow >
                <TableCell align="left"><h3>Shipment Number</h3></TableCell>
                <TableCell align="center"><h3>Name</h3></TableCell>
                <TableCell align="center"><h3>Value</h3></TableCell>
                <TableCell align="center"><h3>Shipment Location</h3></TableCell>
                <TableCell align="center"><h3>#Pieces</h3></TableCell>
                <TableCell align="center"><h3>#Bids</h3></TableCell>
                <TableCell align="center"><h3>Bid Dates</h3></TableCell>
                <TableCell align="center"><h3>Status</h3></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => navigate(`/product/${product.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell align="left">{product.shipmentNumber} </TableCell>
                  <TableCell align="center">{product.name}</TableCell>
                  <TableCell align="center">{product.value}</TableCell>
                  <TableCell align="center">{product.shipmentLocation}</TableCell>
                  <TableCell align="center">{product.numberOfPieces}</TableCell>
                  <TableCell align="center">{product.numberOfBids}</TableCell>
                  <TableCell align="center">{
                    moment(product.startBid).format('MMM DD,YYYY')}
                    <br />
                    {moment(product.endBid).format('MMM DD,YYYY')}
                  </TableCell>
                  <TableCell align="center">
                    <MKTypography variant="body2" component="p" sx={{ border: 1, textAlign: 'center', borderRadius: 2 }} color={product.isSoldOut ? "error" : "success"}>{product.isSoldOut ? "Sold Out" : "Available"}</MKTypography>
                    <MKTypography variant="body2" component="p" sx={{ border: 1, textAlign: 'center', borderRadius: 2, mt: 1 }} color={product.isPublish ? "success" : "error"}>{product.isPublish ? "Publish" : "Not Publish"}</MKTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          {renderPagination()}
        </Grid>
      </Grid>)
  }


  return (
    <MKBox component="section" my={6} py={1}>
      <Container sx={{ mt: 6 }}>{
        renderProducts()}
      </Container>
    </MKBox>
  );
}

export default ProductsList;
