import Lottie from 'react-lottie';
import MKBox from "components/MKBox";
import Container from "@mui/material/Container";

const Loading = () => {
  return (<>
    <MKBox
      minHeight="100vh"
      width="100%"
      sx={{
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Container>
        <Lottie
          options={{
            path: 'https://lottie.host/6b150838-312f-4017-98bc-30446fbe85db/v7BgNNgWke.json',
          }}
          width={200}
          height={200}
        />
      </Container>
    </MKBox>
  </>)
}

export default Loading
