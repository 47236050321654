import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import DefaultNavbar from "Navbars/DefaultNavbar";
import UserInfo from "./sections/UserInfo";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../auth/store/userSlice";
import routes from "routes";
import bgImage from "assets/images/city-profile.jpg";

function Profile() {
  const user = useSelector(({ auth }) => auth.user.data);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user?.id) {
      navigate('/products')
    }
  }, [dispatch, user])

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
        callBack={() => dispatch(logoutUser())}
        action={{
          type: "button",
          label: "Logout",
          color: "info",
        }}
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="15rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <UserInfo />
        </Card>
      </MKBox>

    </>
  );
}

export default Profile;
