
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useDispatch, useSelector } from 'react-redux';
import MKPagination from "components/MKPagination";
import Icon from "@mui/material/Icon";
import { getInquirys } from "../store/InquirysSlice";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";
import MKBadge from "components/MKBadge";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import MuiLink from "@mui/material/Link";


function InquirysList() {
  const navigate = useNavigate();
  const inquirys = useSelector(({ inquirys }) => inquirys.inquirys.data);
  const totalResults = useSelector(({ inquirys }) => inquirys.inquirys.totalResults);
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [numOfpage, setNumOfPage] = useState(1)
  const dispatch = useDispatch()

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  const getProductsData = (page) => {
    setPage(page)
    setLoading(true)
    dispatch(getInquirys({ page, limit: 10 })).then(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    if (totalResults > 0) {
      setNumOfPage(Math.ceil(totalResults / 10))
    }
  }, [])

  const renderPagination = () => {
    const renderPages = (e, index) => {
      return <MKPagination
        onClick={() => getProductsData(index + 1)}
        active={index + 1 === page}
        key={index} item>
        {index + 1}
      </MKPagination>
    }

    return (
      <Container >
        <Grid container item justifyContent="center" xs={12} lg={6} mx="auto">
          <MKPagination>
            {<MKPagination disabled={page === 1} item onClick={() => getProductsData(page - 1)}>
              <Icon>keyboard_arrow_left</Icon>
            </MKPagination>}
            {
              [...Array(numOfpage)].map(renderPages)
            }
            <MKPagination disabled={page === numOfpage} item onClick={() => getProductsData(page + 1)}>
              <Icon>keyboard_arrow_right</Icon>
            </MKPagination>
          </MKPagination>
        </Grid>
      </Container>
    );
  }

  const renderProducts = () => {
    if (!inquirys || loading) {
      return <></>
    }
    return (
      <Grid container spacing={3} sx={{ mb: 10 }} key={"title"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableBody>
              <TableRow >
                <TableCell align="left"><h3>Name</h3></TableCell>
                <TableCell align="center"><h3>Email</h3></TableCell>
                <TableCell align="center"><h3>Phone Number</h3></TableCell>
                <TableCell align="center"><h3>createdAt</h3></TableCell>
                <TableCell align="center"><h3>Message</h3></TableCell>
                <TableCell align="center"><h3>replay</h3></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {inquirys.map((inquiry) => (
                <TableRow
                  key={inquiry.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">
                    {inquiry.name}
                    {inquiry.userId === 'guest' && <MKBadge size="xs" badgeContent="Guest" variant="contained" color="secondary" container circular />}
                  </TableCell>
                  <TableCell align="center">{inquiry.userEmail}</TableCell>
                  <TableCell align="center">{inquiry.phoneNumber}</TableCell>
                  <TableCell align="center">{moment(inquiry.createdAt).format('MMM DD,YYYY hh:mm')}</TableCell>
                  <TableCell align="center">
                    {inquiry.desc.length > 50 ? <CustomWidthTooltip title={inquiry.desc} placement="bottom">
                      <MKTypography variant="button" fontWeight="regular">
                        {inquiry.desc.slice(0, 50)}...
                      </MKTypography>
                    </CustomWidthTooltip> : inquiry.desc}
                  </TableCell>
                  <TableCell align="center">
                    <MKTypography component={MuiLink} href={`mailto:${inquiry.userEmail}?subject=replay%20mail&body=${inquiry.desc}`}>
                      <Icon sx={{ fontWeight: "bold" }}>replay</Icon>
                    </MKTypography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12}>
          {renderPagination()}
        </Grid>
      </Grid>)
  }


  return (
    <MKBox component="section" my={6} py={1}>
      <Container sx={{ mt: 6 }}>{
        renderProducts()}
      </Container>
    </MKBox>
  );
}

export default InquirysList;
