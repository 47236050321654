import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "Navbars/DefaultNavbar";
import routes from "routes";
import bgImage from "assets/images/bg-presentation.jpg";
import colors from "assets/theme/base/colors";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import UsersList from "pages/Users/components/UsersList";
import { getUsers } from "./store/usersSlice";

function Users() {
  const user = useSelector(({ auth }) => auth.user.data);
  const dispatch = useDispatch()
  const isLogedin = user?.id
  const navigate = useNavigate();

//   const users = useSelector(({ users }) => products.users.data);
  // const totalResults = useSelector(({ products }) => products.products.totalResults);
  // const myProducts = useSelector(({ products }) => products.products.myProducts);
  const getUsersData = () => {
    dispatch(getUsers({ page: 0, limit: 10 }))
  }

  useEffect(() => {
    getUsersData()
  }, [])

  return (
    <>
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundColor: colors.dark.main
        }}
      />
      <Card
        minHeight="100%"
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -5,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <UsersList />
      </Card>
    </>
  );
}

export default Users;
