
import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from "moment";

const ProductHistory = ({ history }) => {

  const renderProducts = () => {
    if (history.length === 0) {
      return <MKTypography> No records for this product</MKTypography>
    }
    return (
      <Grid container spacing={3} sx={{ mb: 10 }} key={"title"}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableBody>
              <TableRow >
                <TableCell align="left"><h3>User ID</h3></TableCell>
                <TableCell align="center"><h3>Email</h3></TableCell>
                <TableCell align="center"><h3>Name</h3></TableCell>
                <TableCell align="center"><h3>Value</h3></TableCell>
                <TableCell align="center"><h3>Bid Value</h3></TableCell>
                <TableCell align="center"><h3>CreatedAt</h3></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {history.map((record) => (
                <TableRow
                  key={record.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{record.userId} </TableCell>
                  <TableCell align="center">{record.userEmail}</TableCell>
                  <TableCell align="center">{record.userName}</TableCell>
                  <TableCell align="center">{record.value}</TableCell>
                  <TableCell align="center">{record.bidValue}</TableCell>
                  <TableCell align="center">{moment(record.createdAt).format('MMM DD,YYYY hh:mm')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>)
  }


  return (
    <MKBox component="section" my={6} py={1}>
      <Container sx={{ mt: 6 }}>{
        renderProducts()}
      </Container>
    </MKBox>
  );
}

export default ProductHistory;
