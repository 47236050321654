import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';


export const getProducts = createAsyncThunk(
  'productsApp/products/getProducts',
  async ({ page, limit, userId }, { dispatch }) => {
    const response = await axios.get('/product', {
      params: {
        page: page,
        limit,
        userId,
      },
    });

    dispatch(setTotalResults(response.data.total));
    return response.data.results;
  }
);


// const productsAdapter = createEntityAdapter({
//   selectId: model => model.id,
// });

// export const { selectAll: selectProducts, selectById: selectProductsById } = productsAdapter.getSelectors(
//   state => state.products
// );

const productsSlice = createSlice({
  name: 'productsApp/products',
  initialState: {
    totalResults: 0,
    data: [],
    myProducts: []
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },
  extraReducers: {
    [getProducts.fulfilled]: (state, action) => {
      state.data = action.payload
    },
  },
});

export const {
  setTotalResults,
} = productsSlice.actions;

export default productsSlice.reducer;

