import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';


export const getBlog = createAsyncThunk(
  'blogApp/blog/getBlog',
  async (productId, { dispatch }) => {
    const response = await axios.get(`/blogs/${productId}`);
    return response.data;
  }
);

export const updateBlog = createAsyncThunk(
  'blogApp/blog/updateBlog',
  async ({ blog, blogId }) => {
    const response = await axios.patch(`blogs/${blogId}`, blog);
    return response.data;
  }
);

export const createBlog = createAsyncThunk(
  'blogApp/blog/createBlog',
  async (product) => {
    const response = await axios.post(`blogs`, product);
    return response.data;
  }
);

export const deleteBlog = createAsyncThunk(
  'blogApp/blog/deleteBlog',
  async (productId) => {
    const response = await axios.delete(`blogs/${productId}`);
    return response.data;
  }
);

const productsSlice = createSlice({
  name: 'blogApp/blog',
  initialState: {
    name: '',
    description: '',
    images: [],
    isPublish: true,
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },
  extraReducers: {
    [getBlog.fulfilled]: (state, action) => {
      state = action.payload
    },
  },
});

export const {
  setTotalResults,
} = productsSlice.actions;

export default productsSlice.reducer;

