import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from 'axios';


export const getProduct = createAsyncThunk(
  'productApp/product/getProduct',
  async (productId, { dispatch }) => {
    const response = await axios.get(`/product/${productId}`);
    return response.data;
  }
);

export const updateValue = createAsyncThunk(
  'productApp/product/updateValue',
  async ({ productId, bidValue }) => {
    const response = await axios.post(`product/${productId}`, { bidValue });
    return response.data;
  }
);

export const createProduct = createAsyncThunk(
  'productApp/product/createProduct',
  async (product) => {
    const response = await axios.post(`product`, product);
    return response.data;
  }
);

export const updateProduct = createAsyncThunk(
  'productApp/product/updateProduct',
  async ({ product, productId }) => {
    const response = await axios.patch(`product/${productId}`, product);
    return response.data;
  }
);

export const deleteProduct = createAsyncThunk(
  'productApp/product/deleteProduct',
  async (productId) => {
    const response = await axios.delete(`product/${productId}`);
    return response.data;
  }
);

// const productsAdapter = createEntityAdapter({
//   selectId: model => model.id,
// });

// export const { selectAll: selectProducts, selectById: selectProductsById } = productsAdapter.getSelectors(
//   state => state.products
// );

const productsSlice = createSlice({
  name: 'productApp/product',
  initialState: {
    name: '',
    description: '',
    isSoldOut: false,
    images: [],
    value: 0
  },
  reducers: {
    setTotalResults: {
      reducer: (state, action) => {
        state.totalResults = action.payload ? action.payload : 0;
      },
      prepare: totalResults => ({ payload: totalResults || '' }),
    },
  },
  extraReducers: {
    [getProduct.fulfilled]: (state, action) => {
      state = action.payload
    },
  },
});

export const {
  setTotalResults,
} = productsSlice.actions;

export default productsSlice.reducer;

