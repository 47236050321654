import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKError from "components/MKError";
import DefaultNavbar from "Navbars/DefaultNavbar";
import routes from "routes";
import { useDispatch, useSelector } from 'react-redux';
import { getBlog, deleteBlog } from "./store/blogSlice";
import { useParams } from "react-router-dom";
import MKButton from "components/MKButton";
import BlogForm from "./components/Form";
import colors from "assets/theme/base/colors";
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

function Blog() {
  const [blog, setBlog] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const user = useSelector(({ auth }) => auth.user.data);
  const dispatch = useDispatch()
  const { blogId } = useParams()
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const defaultBlog = {
    name: "",
    title: "",
    description: "",
    images: [],
    isPublish: true,
  }

  const getBlogData = () => {
    if (blogId === 'new') {
      setBlog(defaultBlog)
      setLoading(false)
    } else {
      setLoading(true)
      dispatch(getBlog(blogId)).then(({ payload }) => {
        if (payload) {
          setBlog(payload)
        } else {
          setError(true)
        }
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        setError(true)
      })

    }
  }

  useEffect(() => {
    getBlogData()
  }, [])

  const getNavbar = () => {
    if (user?.id) {
      return <DefaultNavbar
        routes={routes}
        sticky
      />
    }
    return <DefaultNavbar
      routes={routes}
      action={{
        type: "internal",
        route: "/sign-in",
        label: "Login",
        color: "info",
      }}
      sticky
    />
  };

  const handleRemove = () => {
    dispatch(deleteBlog(blogId)).then(({ payload }) => {
      if (payload?.success) {
        navigate("/blogs")
      } else {
        setError(true)
      }
    })
  }

  return (
    <>
      {getNavbar()}
      <MKBox
        minHeight="20vh"
        width="100%"
        sx={{
          backgroundColor: colors.dark.main
        }}
      >
        <Container>
        </Container>
      </MKBox>
      <Card
        sx={{
          minHeight: "50vh",
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -5,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >

        {error && <MKError />}
        {(!error && blogId !== 'new') && <Container>
          <MKBox sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <MKButton onClick={handleOpen} variant="gradient" color="error">Delete Blog</MKButton>
          </MKBox>
        </Container>}
        { (blog && !loading && !error) && <BlogForm blog={blog} />}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MKBox sx={style}>
            <MKTypography id="modal-modal-title" variant="h6" component="h2">
              Alert
            </MKTypography>
            <MKTypography id="modal-modal-description" sx={{ mt: 2 }}>
              are you sure want to delete this Blog?
            </MKTypography>
            <Container>
              <MKBox sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 4 }}>
                <MKButton onClick={handleRemove} variant="outlined" color="error">Delete</MKButton>
                <MKButton sx={{ mr: 1 }} onClick={handleClose} variant="outlined" color="info">Cancel</MKButton>
              </MKBox>
            </Container>
          </MKBox>
        </Modal>
      </Card>
    </>
  );
}

export default Blog;
