import { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import MKInput from "components/MKInput";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import { approved } from "../store/bidsEndedSlice";
import { useDispatch } from 'react-redux';

function ProductPopup({ show, setShow, product, callBack }) {
  const toggleModal = () => setShow(!show);
  const dispatch = useDispatch()
  const [taxPercentage, setTax] = useState(0)
  const [buyerPremiumPercentage, setBuyerPremiumPercentage] = useState(0)
  const [totalValue, setTotal] = useState(product.value)
  const [formError, setFormError] = useState('')

  const setErrors = (error) => {
    setFormError(error)
    setTimeout(() => {
      setFormError('')
    }, 5000);
  }
  const approvedProduct = () => {
    const isValid = checkValidations()
    if (isValid) {
      dispatch(approved({ productId: product.id, isApproved: !product.isApproved, taxPercentage, buyerPremiumPercentage }))
        .then((res) => {
          const { payload } = res
          if (payload?.id) {
            callBack()
            setShow(false)
          } else {
            setErrors('something went wrong, please check if this bid was already approved and try again later.')
          }
        }
        )
    } else {
      setErrors('Invalid tax value')
    }
  }

  const calcTax = (action, tax) => {
    let newTotal = product.value
    const taxNumber = Number(tax)
    if (action === "buyerPremium") {
      if (tax === '') setBuyerPremiumPercentage(0)
      setBuyerPremiumPercentage(taxNumber)
      newTotal += ((product.value * taxNumber) / 100) + ((product.value * taxPercentage) / 100)
    } else {
      if (tax === '') setTax(0)
      setTax(taxNumber)
      newTotal += ((product.value * taxNumber) / 100) + ((product.value * buyerPremiumPercentage) / 100)
    }
    setTotal(newTotal)
  }

  const checkValidations = () => {
    return taxPercentage !== '' && (Number(taxPercentage)) >= 0
  }

  return (
    <Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
      <Slide direction="up" in={show} timeout={500}>
        <MKBox
          position="relative"
          margin={5}
          display="flex"
          flexDirection="column"
          borderRadius="xl"
          bgColor="white"
          shadow="xl"
          padding={1}
        >
          <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2} width={'50%'}>
            <MKTypography variant="h5">{product.name}</MKTypography>
            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
          </MKBox>
          <Divider sx={{ my: 0 }} />
          <MKBox p={2}>
            <MKTypography variant="h5" color="secondary" fontWeight="regular">
              User Information
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Name: {product.user.firstName + ' ' + product.user.lastName}
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Email: {product.user.email}
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Mob#: {product.user.phoneNumber}
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Address: {product.user.address}
            </MKTypography>
            <Divider sx={{ my: 1 }} />
            <MKTypography variant="h5" color="secondary" fontWeight="regular">
              Product Information
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Shipment Number: {product.shipmentNumber}
            </MKTypography>
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
              Last Value: ${product.value}
            </MKTypography>


            <Divider sx={{ my: 1 }} />
            <MKTypography variant="h5" color="secondary" fontWeight="regular">
              Tax Information
            </MKTypography>
            <Grid container spacing={3}>
              <Grid item >
                <MKInput variant="standard" defaultValue={product.value} disabled label="Value" fullWidth />
              </Grid>
              <Grid item >
                <MKTypography variant="body2" color="secondary" fontWeight="regular" mt={2}>
                  +
                </MKTypography>
              </Grid>
              <Grid item >
                <Grid item >
                  <MKInput variant="standard" value={buyerPremiumPercentage} InputLabelProps={{ shrink: true, min: 0 }} onChange={(event) => calcTax('buyerPremium', event.target.value)} type="number" required label="Buyer Premium:(%)" fullWidth />
                </Grid>
              </Grid>
              <Grid item >
                <MKTypography variant="body2" color="secondary" fontWeight="regular" mt={2}>
                  +
                </MKTypography>
              </Grid>
              <Grid item >
                <MKInput variant="standard" value={taxPercentage} InputLabelProps={{ shrink: true, min: 0 }} onChange={(event) => calcTax('tax', event.target.value)} type="number" required label="Tax(%)" fullWidth />
              </Grid>
              <Grid item >
                <MKTypography variant="body2" color="secondary" fontWeight="regular" mt={2}>
                  =
                </MKTypography>
              </Grid>
              <Grid item >
                <MKTypography variant="body2" color="secondary" fontWeight="regular" mt={2}>
                  ${totalValue}
                </MKTypography>
              </Grid>
            </Grid>
          </MKBox>
          <Divider sx={{ my: 0 }} />
          {formError !== '' && <Grid item xs={12}>
            <MKTypography variant="body2" color="error">{formError}</MKTypography>
          </Grid>}
          <MKBox display="flex" justifyContent="space-between" p={1.5}>
            <MKButton variant="gradient" color="dark" onClick={toggleModal}>
              close
            </MKButton>
            <MKButton variant="gradient" color="info" onClick={() => approvedProduct()}>
              {!product.isApproved ? 'Approved' : 'not Approved'}
            </MKButton>
          </MKBox>
        </MKBox>
      </Slide>
    </Modal>
  )
}

export default ProductPopup;