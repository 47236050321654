import { combineReducers } from '@reduxjs/toolkit';
import auth from '../auth/store';
import products from '../pages/Products/store';
import product from '../pages/Product/store';
import users from '../pages/Users/store'
import inquirys from '../pages/Inquirys/store'
import bidsEnded from '../pages/BidsEnded/store'
import blogs from '../pages/Blogs/store'
import blog from '../pages/BidsEnded/store'
const createReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    products,
    product,
    users,
    inquirys,
    bidsEnded,
    blogs,
    blog,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === 'user/userLoggedOut') {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
