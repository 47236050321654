import Lottie from 'react-lottie';
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
const ErrorMsg = () => {
  return (<>
    <MKBox
      width="100%"
      sx={{

        display: "grid",
        placeItems: "center",
      }}
    >
      <MKTypography color="error">Sorry, we couldn't retrieve the data you requested. Please try again later or contact support for assistance</MKTypography>
      <Lottie
        options={{
          path: 'https://lottie.host/6a116586-8bca-4bef-8995-1950d1510fa2/TEmjU3e3GO.json',
        }}
        width={300}
        height={300}
      />
    </MKBox>
  </>)
}

export default ErrorMsg
