import axios from 'axios';
import React, { Component } from 'react';
/* eslint-disable camelcase */

class JwtService extends Component {
  async init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    if (process.env.NODE_ENV !== 'production') {
      axios.defaults.baseURL = 'http://localhost:1337/api/admin/';
    } else {
      axios.defaults.baseURL = 'https://api.movingtreasure.com/api/admin/';
    }
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.setSession(null);
      return;
    }
    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
    } else {
      this.setSession(null);
    }
  };

  signInWithEmailAndPassword = (email, password) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/login', {
          email,
          password,
        })
        .then(response => {
          if (response.data.adminUser) {
            this.setSession(response.data.token.accessToken);
            resolve(response.data.adminUser);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  createUser = (userData) =>
    new Promise((resolve, reject) => {
      axios
        .post('/auth/signup',
          userData
        )
        .then(response => {
          if (response.data.user) {
            this.setSession(response.data.token.accessToken);
            resolve(response.data.adminUser);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/auth/me')
        .then(response => {
          if (response.data) {
            resolve(response.data);
          } else {
            this.logout();
            reject(response.data);
          }
        })
        .catch(error => {
          reject(error);
          this.logout();
        });
    });
  }

  setSession = access_token => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
  };

  isAuthTokenValid = access_token => {
    if (!access_token) {
      return false;
    }
    return true;
  };
  getAccessToken = () => window.localStorage.getItem('jwt_access_token');
}

const instance = new JwtService();

export default instance;
