import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const createInquiry = createAsyncThunk(
  'inquiryApp/inquiry/createInquiry',
  async (reqBody) => {
    const response = await axios.post(`inquiries`, reqBody);
    return response.data;
  }
);

