import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MenuItem from "@mui/material/MenuItem";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";
import { useSelector, useDispatch } from 'react-redux';
import profilePicture from "assets/images/bruce-mars.jpg";
import { updateUserData } from "auth/store/userSlice";
import { useNavigate } from "react-router-dom";
const UserInfo = () => {
  const dispatch = useDispatch()
  const [isSaved, setIsSaved] = useState(false);
  const [isError, setIsError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [userData, setUserData] = useState(null);
  const user = useSelector(({ auth }) => auth.user.data);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.id) {
      navigate('/products')
    } else {
      setUserData(user)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setAddress(user.address)
      setPhoneNumber(user.phoneNumber)
    }
  }, [dispatch, user])

  const saveUserData = () => {
    const reqParams = {
      firstName,
      lastName,
      phoneNumber,
      address
    }
    const userID = userData.id
    dispatch(updateUserData({ userID, reqParams })).then(({ payload }) => {
      if (payload.id) {
        setIsSaved(true)
        setTimeout(() => {
          setIsSaved(false)
        }, 4000);
      } else {
        setIsError(true)
        setTimeout(() => {
          setIsError(false)
        }, 4000);
      }
    })
  }

  const checkIsValid = () => {
    return firstName !== '' && lastName !== '' && phoneNumber !== '' && address !== ''
  }

  const buttonEnabled = () => {
    return firstName !== userData.firstName || lastName !== userData.lastName || phoneNumber !== userData.phoneNumber || address !== userData.address
  }

  if (!user.id || !userData) return <></>
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={userData?.photoURL || profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" >
            <Grid container py={6} xs={12} md={3} lg={2} justifyContent="center" mx="auto" >
              <Grid item spacing={3} mb={3}>
                <Grid>
                  <MKTypography component="span" variant="body2" color="text">
                    {userData?.displayName}
                  </MKTypography>
                </Grid>
                <Grid>
                  <MKTypography component="span" variant="body2" color="text">
                    {userData?.email}
                  </MKTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={9} lg={10} justifyContent="center" mx="auto" >
              <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
                <MKBox width="100%" component="form">
                  {isError && <MKAlert color="error">Error to save please try again later</MKAlert>}
                  {isSaved && <MKAlert color="success">Saved successfully</MKAlert>}
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput required defaultValue={userData?.firstName} variant="standard" onChange={(e) => setFirstName(e.target.value)} label="First Name" fullWidth />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput required defaultValue={userData?.lastName} variant="standard" label="Last Name" fullWidth onChange={(e) => setLastName(e.target.value)} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput required defaultValue={userData.phoneNumber} variant="standard" label="Mob." fullWidth onChange={(e) => setPhoneNumber(e.target.value)} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput defaultValue={userData.address} required variant="standard" label="Address" fullWidth onChange={(e) => setAddress(e.target.value)} />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={2}>
                      {checkIsValid()
                        ? <MKButton fullWidth disabled={!buttonEnabled()} onClick={() => saveUserData()} variant="gradient" color="info">
                          Save
                        </MKButton>
                        : <MKButton fullWidth disabled={!buttonEnabled()} type="submit" variant="gradient" color="info">
                          Save
                        </MKButton>
                      }
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default UserInfo;
